<template>
  <div>
    <b-row>
      <b-col>
        <h3 class="">
          {{ $t('firstRound') }}
        </h3>
        <span class="text-muted">
          {{ $t('imageInfo') }}
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <pricing-table :items="[...quote]" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <pricing-list
          :round-stage="'firstRound'"
          :products="supplierQuoteItems"
        />
      </b-col>
    </b-row>
    <!-- not avaiable products not in serializaer -->
    <!-- <b-row class="mt-2">
      <b-col class="p-0">
        <div class="custom-container">
          <h1>{{ $t('notAvaiableProducts') }}</h1>
          <div
            v-for="product in supplierQuoteItems"
            :key="product.id"
          >
            <not-avaiable-product-row :product="product" />
          </div>
        </div>
      </b-col>
    </b-row> -->
    <b-row class="mt-3 justify-content-end">
      <b-button
        size="lg"
        variant="primary"
        :to="{ name: 'supplierQuotes', params: { id: $route.params.branchId } }"
      >
        {{ $t('return') }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PricingTable from '@/components/pricing/BasicTable.vue'
import PricingList from '@/components/pricing/PricingList.vue'
// import notAvaiableProductRow from '@/components/pricing/notAvaiableProductRow.vue'

export default {
  components: {
    PricingTable,
    PricingList,
    // notAvaiableProductRow,
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('quotes', ['quote', 'supplierQuoteItems']),
  },

  created() {
    // this.fetchSupplierBranchQuoteItems({
    //   quoteId: this.$route.params.quoteId,
    //   branchId: this.$route.params.branchId,
    // })
    // this.fetchQuote(this.$route.params.quoteId)
  },

  methods: {
    ...mapActions('quotes', ['fetchQuote', 'fetchSupplierBranchQuoteItems']),
  },
}
</script>

<style></style>
